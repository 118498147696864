<template>
  <Layout :tituloPagina="'Network | NOCs'">
    <div class="row">
      <div class="col-lg-9">
        <div class="row mb-4">
          <div class="col-lg-12 text-right">
            <router-link :to="{ name: 'nuevoNoc' }" class="btn btn-success">
              <span class="d-none d-sm-block">
                <i class="mdi mdi-plus-thick"></i>
                Nuevo
              </span>
              <span class="d-block d-sm-none">
                <i class="mdi mdi-plus-thick"></i>
              </span>
            </router-link>
          </div>
        </div>
    
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Listado de centros de operación de red (NOC)
            </h4>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table id="tblListadoNocs" class="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th style="width: 10px">No.</th>
                    <th>Descripción</th>
                    <th>Direccion IP</th>
                    <th style="width: 150px">Permisos</th>
                    <th style="width: 180px" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="noc,index in nocs" :key="noc.id">
                    <td>{{index+1}}</td>
                    <td class="fw-semibold">
                      {{ noc.descripcion }}
                    </td>
                    <td>
                      {{ noc.direccion_ip }}
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'permisosRouterNoc',
                          params: { id: noc.id }
                        }"
                        class="btn btn-outline-success btn-sm"
                      >
                        Permisos
                      </router-link>
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'edicionNoc',
                          params: { id: noc.id }
                        }"
                        class="btn btn-warning btn-sm"
                      >
                        <i class="mdi mdi-content-save-edit-outline"></i>
                        Editar
                      </router-link>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="preguntaEliminarNoc(noc)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-center">
          <paginador
            :pag="page"
            :totpag="totpag"
            :epp="epp"
            @cargar-pagina="cargarPagina"
            @epp-actualizado="
              epp = $event,
              refrescarNocs()
            "
          ></paginador>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Paginador from '@/components/Paginador.vue'
import NocSrv from '@/services/NocSrv.js'
import Swal from 'sweetalert2'
import API from '@/API.js'
export default {
  name: 'ListadoNocs',
  components: {
    Layout,
    Paginador
  },
  data() {
    return {
      API: API,
      nocs: [],
      baseUrl: window.location.origin,
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoNocs.por_pagina') || 10),
      totnocs: 0, // Total de NOC
      totpag: 0, // Total de páginas
      ultimaPagina: 1
    }
  },

  created: function() {
    var self = this

    // Carga de los querys
    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    // Carga inicial de los aps
    self.refrescarNocs()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarNocs({page: n})
    },
    
    preguntaEliminarNoc: function(noc) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar NOC",
        html: '¿Está seguro que quiere eliminar el NOC <strong>'+noc.descripcion+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          NocSrv.eliminar(noc.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarNocs()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el punto de acceso'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarNocs: function() {
      var self = this
      
      iu.spinner.mostrar('#tblListadoNocs')

      // Registro de los querys
      self.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato
      }

      NocSrv.nocsJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoNocs.por_pagina', self.epp)
        let page = response.data
        self.nocs = page.data
        self.totnocs = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarNocs()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los NOCs'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListadoNocs')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListadoNocs')
  }
}
</script>

<style scoped>

</style>
