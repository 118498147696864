import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/nocs`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(noc) {
    return apiClient.post(`${noc.id}`, noc)
  },

  agregarAccesoATravesDeLosRouters(idNoc, idsRouters) {
    let params = { ids_routers: idsRouters }
    return apiClient.post(`${idNoc}/routers`, params)
  },

  eliminar(idNoc) {
    return apiClient.delete(`${idNoc}`)
  },

  eliminarAccesoATravesDeLosRouters(idNoc, idsRouters) {
    let params = { ids_routers: idsRouters }
    return apiClient.delete(`${idNoc}/routers`, { params: params })
  },

  guardar(noc) {
    return apiClient.post('', noc)
  },

  nocJSON(idNoc) {
    return apiClient.get(`${idNoc}.json`)
  },

  nocsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/nocs.json`, { params: params })
  },


  routersJSON(idNoc) {
    return apiClient.get(`${idNoc}/routers.json`)
  },
}
